var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("b-modal", {
    attrs: {
      "hide-header": "",
      "hide-footer": "",
      centered: "",
      "no-close-on-backdrop": "",
      "no-stacking": "",
      size: "lg"
    },
    model: {
      value: _vm.showDialog,
      callback: function ($$v) {
        _vm.showDialog = $$v;
      },
      expression: "showDialog"
    }
  }, [_c("b-alert", {
    attrs: {
      variant: "warning",
      show: _vm.alert.show
    }
  }, [_vm._v(" " + _vm._s(_vm.alert.message) + " ")]), _c("b-row", {
    staticClass: "mb-4",
    attrs: {
      "align-h": "between"
    }
  }, [_c("b-col", {
    attrs: {
      cols: "10"
    }
  }, [_c("img", {
    attrs: {
      src: require("@/assets/images/icon_logo_violet.svg"),
      alt: ""
    }
  })]), _c("b-col", {
    staticClass: "text-right"
  })], 1), _c("b-row", {
    staticClass: "mt-4 pr-4"
  }, [_c("b-col", {
    attrs: {
      offset: "3",
      cols: "8"
    }
  }, [_c("b-row", [_c("b-col", [_vm.displayMode == "auto" ? _c("h2", {
    staticClass: "heading"
  }, [_vm._v(" Are you sure you want to cancel this prescription? ")]) : _vm._e(), _vm.displayMode == "auto" ? _c("p", [_vm._v(" This prescription will be automatically refunded because it hasn't been sent to the pharmacy yet. ")]) : _vm._e(), _vm.displayMode == "default" ? _c("h2", {
    staticClass: "heading"
  }, [_vm._v(" This prescription has already been sent to the pharmacy. ")]) : _vm._e(), _vm.displayMode == "default" ? _c("p", [_vm._v(" If it has not been set out to the patient yet, it will automatically be refunded. Would you like to request for it to be refunded even if it has been shipped to the patient? ")]) : _vm._e(), _vm.displayMode == "rush" ? _c("h2", {
    staticClass: "heading"
  }, [_vm._v(" This prescription has been sent to the pharmacy. ")]) : _vm._e(), _vm.displayMode == "rush" ? _c("p", [_vm._v(" This prescription was rushed and cancellation will create a new Refund Request ticket. ")]) : _vm._e()])], 1), _c("b-row", {
    staticClass: "mt-2"
  }, [_c("b-col", [_c("span", {
    staticClass: "text-primary text-small"
  }, [_vm._v("Patient")]), _c("br"), _c("span", {
    staticClass: "text-capitalize"
  }, [_vm._v(" " + _vm._s(_vm.prescriptionData.search_fullname) + " ")])])], 1), _vm.displayMode == "default" ? _c("b-row", {
    staticClass: "mt-2"
  }, [_c("b-col", [_c("span", {
    staticClass: "text-primary text-small"
  }, [_vm._v("Refund")]), _c("b-row", [_c("b-col", {
    attrs: {
      cols: "2"
    }
  }, [_c("b-form-radio", {
    attrs: {
      value: true
    },
    model: {
      value: _vm.refund,
      callback: function ($$v) {
        _vm.refund = $$v;
      },
      expression: "refund"
    }
  }, [_vm._v(" Yes ")])], 1), _c("b-col", {
    attrs: {
      cols: "2"
    }
  }, [_c("b-form-radio", {
    attrs: {
      value: false
    },
    model: {
      value: _vm.refund,
      callback: function ($$v) {
        _vm.refund = $$v;
      },
      expression: "refund"
    }
  }, [_vm._v(" No ")])], 1)], 1)], 1)], 1) : _vm._e(), _c("b-row", {
    staticClass: "mt-2"
  }, [_c("b-col", [_c("span", {
    staticClass: "text-primary text-small"
  }, [_vm._v("Prescription")]), _c("br"), _c("span", [_vm._v(" " + _vm._s(_vm.prescriptionData.medicinename) + " | " + _vm._s(_vm.prescriptionData.dosage) + " ")])])], 1), _c("b-row", {
    staticClass: "mt-2"
  }, [_c("b-col", [_c("span", {
    staticClass: "text-primary text-small"
  }, [_vm._v("Reason")]), _c("b-form-select", {
    attrs: {
      options: _vm.reasons,
      disabled: _vm.loading || _vm.processing
    },
    model: {
      value: _vm.cancellationReason,
      callback: function ($$v) {
        _vm.cancellationReason = $$v;
      },
      expression: "cancellationReason"
    }
  })], 1)], 1), _c("b-row", {
    staticClass: "mt-3"
  }, [_c("b-col", [_c("span", {
    staticClass: "text-primary text-small"
  }, [_vm._v("Notes")]), _c("b-form-textarea", {
    attrs: {
      placeholder: "Notes",
      disabled: _vm.loading || _vm.processing,
      rows: "4"
    },
    model: {
      value: _vm.cancellationNotes,
      callback: function ($$v) {
        _vm.cancellationNotes = $$v;
      },
      expression: "cancellationNotes"
    }
  })], 1)], 1), _c("b-row", {
    staticClass: "mt-4"
  }, [_c("b-col", [_c("b-button", {
    attrs: {
      pill: "",
      block: "",
      variant: "outline-primary",
      disabled: _vm.processing
    },
    on: {
      click: _vm.closeDialog
    }
  }, [_vm._v(" Back ")])], 1), _c("b-col", [_c("b-button", {
    attrs: {
      pill: "",
      block: "",
      variant: "secondary",
      disabled: _vm.processing || _vm.loading
    },
    on: {
      click: _vm.cancelSinglePrescription
    }
  }, [_vm._v(" Confirm ")])], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };