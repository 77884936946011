import moment from 'moment';
import { PrescriptionService } from '@/services/prescription.service';
import { LookupService } from '@/services/lookup.service';
export default {
  props: {
    prescriptionData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      showDialog: false,
      alert: {
        show: false,
        message: ''
      },
      reasons: [],
      refund: false,
      cancellationReason: '',
      cancellationNotes: '',
      processing: false,
      loading: false
    };
  },
  computed: {
    within24Hour() {
      if (!this.prescriptionData) return false;
      const timePrescribed = new moment(this.prescriptionData.scheduleddatetimestamp * 1000);
      const now = new moment();
      return now.diff(timePrescribed, 'hours') < 12;
    },
    displayMode() {
      if (!this.prescriptionData) return 'default';else if (this.prescriptionData.timestamprushed) {
        return 'rush';
      } else {
        const timePrescribed = new moment(this.prescriptionData.scheduleddatetimestamp * 1000);
        const now = new moment();
        if (now.diff(timePrescribed, 'hours') < 24) {
          return 'auto';
        } else {
          return 'default';
        }
      }
    }
  },
  watch: {
    async showDialog(show) {
      if (show) {
        this.alert.show = false;
        this.loading = true;
        // Load cancellation reasons
        const reasons = await LookupService.getCancelReason();
        this.reasons = reasons.data.map(reason => ({
          text: reason.name,
          value: reason.id
        }));
        this.loading = false;
      } else {
        this.refund = false;
        this.cancellationReason = '';
        this.cancellationNotes = '';
      }
    }
  },
  methods: {
    showAlert(msg) {
      this.alert.show = true;
      this.alert.message = msg;
    },
    async cancelSinglePrescription() {
      this.alert.show = false;
      if (!this.cancellationReason || !this.cancellationNotes) {
        this.showAlert('Please fill in all the fields.');
        return;
      }
      try {
        const body = {
          cancellationreason: this.cancellationReason,
          cancellationnotes: this.cancellationNotes
        };
        if (this.within24Hour) {
          body.refund = true;
        } else {
          body.refund = this.refund;
        }
        this.processing = true;
        await PrescriptionService.cancelSinglePrescription(this.prescriptionData.id, body);
        this.$emit('refresh-prescriptions');
        this.processing = false;
        this.closeDialog();
      } catch (err) {
        this.showAlert(err);
        this.processing = false;
      }
    },
    closeDialog() {
      this.$emit('reset-selected-row');
      this.showDialog = false;
    }
  }
};